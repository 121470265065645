import about from "../assets/icons/about.png";
import accounts from "../assets/icons/accounts.png";
import help from "../assets/icons/alert_wh.png";
import reports from "../assets/icons/attach-blk.png";
import scheduler from "../assets/icons/calendar_wh.png";
import camera from "../assets/icons/camera.png";
import device from "../assets/icons/device.png";
import orders from "../assets/icons/orders.png";
import tools from "../assets/icons/settings-blk.png";
import { Privileges } from "../constants/PrivilegeConstants";
import {
  RETURN_EQUIPMENT_PAGE,
  CHECKIN_PAGE,
  CREATE_VEHICLES_PAGE,
  REVEAL_SEARCH_UNIT,
  UPDATE_ACTIVE_UNITS,
  UPLOAD_UNITS,
  OPEN_JOBS,
  ORDER_WORKFLOW_RETRY,
  REPORTS,
  CREATE_ORDER,
  SWAP_DEVICES,
  ASSIGN_CAMERA,
  ASSIGN_CAMERA_V2,
  DECOMM_CAMERA,
  CREATE_CAMERA_PAGE,
  UNASSIGN_CAMERA,
  DEVICE_REPORTS,
  SHIPMENT_DATA,
  SWAP_CAMERA,
  AUDIT_EVENTS_SCHEDULER,
  RBAC_ACCESS,
  AUDIT_LOG,
  HELP,
  ABOUT,
  CREATE_EVENTS,
  ADD_REFURBISHED_UNITS,
  DEVICE_UPDATER,
  ATTRIBUTE_UPDATE_VIEWER,
} from "./pageList";

let CAMERA_SUBMENUS = [];
process.env.REACT_APP_ASSIGN_CAMERA_ENABLED === "true" && CAMERA_SUBMENUS.push(ASSIGN_CAMERA);
process.env.REACT_APP_EVC_ASSIGN_CAMERA_ENABLED === "true" && CAMERA_SUBMENUS.push(ASSIGN_CAMERA_V2);
process.env.REACT_APP_UNASSIGN_CAMERA_ENABLED === "true" && CAMERA_SUBMENUS.push(UNASSIGN_CAMERA);
process.env.REACT_APP_SWAP_CAMERA_ENABLED === "true" && CAMERA_SUBMENUS.push(SWAP_CAMERA);
process.env.REACT_APP_CREATE_CAMERA_ENABLED === "true" && CAMERA_SUBMENUS.push(CREATE_CAMERA_PAGE);
process.env.REACT_APP_DECOMM_CAMERA_ENABLED === "true" && CAMERA_SUBMENUS.push(DECOMM_CAMERA);

const sort = (a, b) => a.name.localeCompare(b.name);
// Add the metadata in this list to insert it in left navigation bar
export const leftNavigationMenus = [
  {
    name: "Device",
    subMenuName: "Device",
    icon: device,
    subMenus: [
      RETURN_EQUIPMENT_PAGE,
      CHECKIN_PAGE,
      CREATE_VEHICLES_PAGE,
      REVEAL_SEARCH_UNIT,
      SWAP_DEVICES,
      UPDATE_ACTIVE_UNITS,
      UPLOAD_UNITS,
      DEVICE_UPDATER,
      ADD_REFURBISHED_UNITS,
      //  UNASSIGN_CAMERA
    ].sort(sort),
  },
  {
    name: "Camera",
    subMenuName: "Create Camera",
    icon: camera,
    // subMenus: [CREATE_CAMERA],
    subMenus: CAMERA_SUBMENUS,
  },
  {
    name: "Accounts",
    subMenuName: "Accounts",
    icon: accounts,
    // subMenus: [
    //   CREATE_ACCOUNT,
    //   DECOMMISSION_VEHICLES,
    //   FAST_START_DATA,
    //   MOVE_VEHICLE,
    //   MOVE_VEHICLE_TEST,
    //   RENEW_ORDER,
    //   TRANSFER_TECH_REFID,
    //   UPDATE_ACCOUNT,
    // ].sort(sort),
    subMenus: [
      // DECOMMISSION_VEHICLES
    ],
  },
  {
    name: "Orders",
    subMenuName: "Orders",
    icon: orders,
    subMenus: [CREATE_ORDER],
    disableSubmenu: true,
  },
  {
    name: "Scheduler",
    // privileges: [Privileges.ENABLE_SP_MENU],
    subMenuName: "Scheduler",
    icon: scheduler,
    subMenus: [OPEN_JOBS],
    disableSubmenu: true,
  },
  {
    name: "Reports",
    subMenuName: "Reports",
    icon: reports,
    subMenus: [
      REPORTS,
      DEVICE_REPORTS,
      SHIPMENT_DATA,
      //   COMPLETE_VTU_CHECK_INS,
      //   CREATE_CAMERA_REPORT,
      //   DECOMMISSIONED_CAMERAS_REPORT,
      //   DECOMMISSIONED_REPORT,
      //   DISCONNECT_REPORT,
      //   DISPLAY_SWAP_REPORT,
      //   INCOMPLETE_VTU_CHECK_INS,
      //   OPPORTUNITY_REPORT,
      //   RENEWAL_REPORT,
      //   SWAP_CAMERA_REPORT,
      //   UNASSIGNED_CAMERAS_REPORTS,
    ].sort(sort),
  },
  {
    name: "Tools",
    subMenuName: "Tools",
    icon: tools,
    subMenus: [
      // CHECK_ACCOUNT_STATUS,
      // DATABASE_BROWSER,
      // DATABASE_EDITOR,
      // DYNAMIC_MAPPING,
      // EVENT_VIEWER,
      // SAP_OUTBOX,
      // SEARCH_REVEAL_UNIT,
      // STUCK_ORDERS,
      AUDIT_LOG,
      RBAC_ACCESS,
      ORDER_WORKFLOW_RETRY,
      AUDIT_EVENTS_SCHEDULER,
      CREATE_EVENTS,
      ATTRIBUTE_UPDATE_VIEWER,
    ].sort(sort),
  },
  {
    name: "Help",
    subMenuName: "Help",
    icon: help,
    subMenus: [HELP],
    disableSubmenu: true,
  },
  {
    name: "About",
    subMenuName: "About",
    icon: about,
    subMenus: [ABOUT],
    disableSubmenu: true,
  },
];

// Add the metadata in this list to insert it into the dropdown of quick links
export const addTabDropdown = [CHECKIN_PAGE, SWAP_DEVICES];

// Add the metadata here if the page should be shown in quick links on the landing page
export const landingPageQuickLinks = [CHECKIN_PAGE, SWAP_DEVICES];
