import { Button } from "@vds/buttons";
import { TextArea } from "@vds/inputs";
import { Line } from "@vds/lines";
import { Loader } from "@vds/loaders";
import { Modal, ModalBody, ModalFooter } from "@vds/modals";
import { Notification } from "@vds/notifications";
import { Body, Title } from "@vds/typography";
import { lazy, useEffect, useState, useCallback } from "react";
import { generate } from "shortid";
import { ReactComponent as Check } from "../../assets/icons/check-circle.svg";
import { ReactComponent as Idle } from "../../assets/icons/circle-fill.svg";
import { ReactComponent as CreateAccount } from "../../assets/icons/Create Account.svg";
import { ReactComponent as DocumentSign } from "../../assets/icons/Document Sign.svg";
import { ReactComponent as Error } from "../../assets/icons/exclamation-triangle.svg";
import { ReactComponent as MilestoneProcess } from "../../assets/icons/Milestone Process.svg";
import { ReactComponent as OpportunityClose } from "../../assets/icons/Opportunity close & sale.svg";
import { ReactComponent as OpportunityCreate } from "../../assets/icons/Opportunity Create.svg";
import { ReactComponent as SetPresentingStage } from "../../assets/icons/Set to presenting stage.svg";
import { ReactComponent as OrderCreateAndRewrite } from "../../assets/icons/Order Creation In SOM.svg";
import { Spacer, SmallText, FlexRow } from "../../components/core/Utility";
import { Accordion } from "../../components/features/Accordion";
import { AccordionItem } from "../../components/features/Accordion/AccordionItem";
import { Colors } from "../../constants/Colors";
import { getMilestoneData, postComment, postCommentOc, getBuyoutStatus } from "../../services/orders/milestone";
import { hasPrivilege } from "../../utility/privileges";
import { ReactComponent as Person } from "../../assets/icons/person-fill.svg";
import { cloneDeep } from "lodash";
import CloseIcon from "../../assets/icons/closeBtn.png";
import { ReactComponent as InfoIcon } from "../../assets/icons/info.svg";

import { Accordion as PrimeAccordion, AccordionTab as PrimeAccordionTab } from "primereact/accordion";
import styled from "@emotion/styled";
import moment from "moment/moment";
import Shipment from "./Shipment";
import useMilestone from "./useMilestone";

const parseDate = (date, dateFormat) => {
  if (date) {
    if (dateFormat) {
      return moment(date).format(dateFormat);
    } else {
      if (date.endsWith("Z")) {
        return moment(date).utc().format("MM/DD/YYYY, h:mm:ss A");
      }
      return new Date(date).toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
      });
    }
  }
  return "";
};

const PrimeAccordionContainer = styled.div`
  .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: none;
  }
`;

const SmallPrimeAccordionContainer = styled.div`
  .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 10px;
  }
  .p-accordion {
    border: 1px solid black;
  }
`;

const makeInfo = (data) => {
  const leftSectionInfo = {};
  leftSectionInfo["Account ID"] = data?.salesForceAccountId ?? "";
  leftSectionInfo["Customer Name"] = data?.accountName ?? "";
  leftSectionInfo["Account Number"] = data?.salesForceAccountNo ?? "";
  leftSectionInfo["ERP BP Number"] = data?.bpNumber ?? "";
  leftSectionInfo["Order Type"] = data?.orderType ?? "";
  leftSectionInfo["Salesforce Opportunity Id"] = data?.salesForceOpportunityId ?? "";
  leftSectionInfo["Opportunity Processed Date"] = parseDate(data?.opportunityCreatedDate);
  leftSectionInfo["Opportunity Closed Date"] = parseDate(data?.opportunityCloseDate);
  leftSectionInfo["SL Opportunity Id"] = data?.superLibraOpportunityId ?? "";
  leftSectionInfo["ERP PO Number"] = data?.erpPoNumber ?? "";
  const result = {};
  result["leftSectionInfo"] = leftSectionInfo;
  result["accountInfo"] = data?.accountInfo;
  result["contractAccountInfo"] = data?.contractAccountInfo;
  result["contactInfo"] = data?.contactInfo;
  result["products"] = data?.products;

  return result;
};

const States = {
  failed: "failed",
  completed: "success",
  progress: "progress",
};

const milestones = [
  {
    first: true,
    title: "Opportunity Created",
    description: "Opportunity Created in SFDC",
    svgIcon: OpportunityCreate,
  },
  {
    title: "Set to presenting stage",
    description: "Opportunity set to presenting stage in SFDC",
    svgIcon: SetPresentingStage,
  },
  {
    title: "Milestone Process 1",
    description: "",
    svgIcon: MilestoneProcess,
  },
  {
    title: "Document Sign",
    description: "Document Sign in SFDC",
    svgIcon: DocumentSign,
  },
  {
    title: "Opportunity close & sale",
    description: "Opportunity closed and sale confirmed in SFDC",
    svgIcon: OpportunityClose,
  },
  {
    title: "Milestone Process 2",
    description: "",
    svgIcon: CreateAccount,
  },
  {
    title: "Order Creation In SOM",
    description: "SL  Order Creation in SOM",
    svgIcon: OrderCreateAndRewrite,
  },
  {
    title: "Reveal Account Creation",
    description: "Create Reveal Account in Reveal Platform",
    svgIcon: OrderCreateAndRewrite,
  },
  {
    title: "Order Rewrite",
    description: "SL Order Rewrite",
    svgIcon: OrderCreateAndRewrite,
  },
  {
    title: "Cancellation",
    description: "SL Order Cancellation",
    svgIcon: OrderCreateAndRewrite,
  },
];

const ErrorMessage = ({ text, callback }) => {
  return (
    <>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          top: "72px",
          left: "500px",
          minHeight: "200px",
          width: "300px",
          borderRadius: "5px",
          boxShadow: "5px 5px 20px 0px black",
        }}
      >
        <FlexRow justifyContent="space-between" css={{ padding: "10px", borderBottom: "1px solid black" }}>
          <Body bold size="large">
            Error Details
          </Body>
          <img
            src={CloseIcon}
            width="20px"
            height="20px"
            onClick={() => {
              callback(null);
            }}
          ></img>
        </FlexRow>
        <div css={{ padding: "10px 17px" }}>
          <Body size="large">{text}</Body>
        </div>
      </div>
    </>
  );
};
const Para = ({ stage, children, anchor, setAddonElement, ...rest }) => {
  const [color, setColor] = useState(Colors.gray);
  const [icon, setIcon] = useState("");
  useEffect(() => {
    const check = <Check width="20px" height="20px"></Check>;
    const error = <Error width="20px" height="20px"></Error>;
    const idle = <Idle width="20px" height="20px"></Idle>;
    let localStage = stage;
    if (typeof stage === "object") {
      localStage = localStage.state;
    }
    if (localStage === States.completed) {
      setColor(Colors.green);
      setIcon(check);
    } else if (localStage === States.failed) {
      setColor(Colors.orange);
      setIcon(error);
    } else if (localStage === States.progress) {
      setColor(Colors.blue);
      setIcon(check);
    } else {
      setColor(Colors.gray);
      setIcon(idle);
    }
  }, [stage]);
  let text = null;
  if (anchor) {
    text = (
      <a href="javascript:void(0)" css={{ paddingLeft: "10px", color: "black" }}>
        {children}
      </a>
    );
  } else {
    text = (
      <span css={{ color: "black" }}>
        {children}
        {stage?.errorMessage && (
          <InfoIcon
            css={{ marginLeft: "10px", transform: "translateY(4px)" }}
            color="black"
            width="20px"
            height="20px"
            onClick={() => setAddonElement(<ErrorMessage text={stage.errorMessage} callback={setAddonElement}></ErrorMessage>)}
          ></InfoIcon>
        )}
      </span>
    );
  }
  return (
    // <div css={{ padding: "10px" }}>
    //   {icon}
    //   <div css={{ display: "inline" }}>
    //     <span>{children}</span>
    //   </div>
    // </div>
    <FlexRow padding="10px" color={color} {...rest} flexWrap="nowrap" alignItems="flex-start">
      <div css={{ marginTop: "2px" }}>{icon}</div>
      <FlexRow css={{ paddingLeft: "10px" }} flexWrap="wrap" alignItems="center">
        {text}
      </FlexRow>
    </FlexRow>
  );
};

const AccordionEntry = ({ label, text }) => {
  let content = text ?? "";
  return (
    <div css={{ padding: "10px" }}>
      <Body bold primitive="span">
        {label}:{" "}
      </Body>{" "}
      <Body primitive="span">{content}</Body>{" "}
    </div>
  );
};

const Info = ({ info, opportunityId, buyoutInfo }) => {
  const [ocCommentsContent, setOcCommentsContent] = useState("");
  const [ocShowRetryModal, setOcShowRetryModal] = useState(false);
  const [ocShowSkipModal, setOcShowSkipModal] = useState(false);
  const [loadingOc, setLoadingOc] = useState(false);
  const [notificationOc, setNotificationOc] = useState();
  const [esnObj, setEsnObj] = useState({});

  const retry = (item) => {
    setEsnObj(item);
    setOcCommentsContent("");
    setOcShowRetryModal(true);
  };
  const skip = (item) => {
    setEsnObj(item);
    setOcCommentsContent("");
    setOcShowSkipModal(true);
  };

  const notifyOc = (content, type) => {
    setNotificationOc(<Notification key={generate()} type={type} title={content} fullBleed={true} />);
  };

  const commentsChangeCallbackOc = (e) => {
    setOcCommentsContent(e.target.value);
  };

  const submitRetrySkipCommentEsn = () => {
    if (
      esnObj?.esn !== undefined &&
      esnObj?.esn !== null &&
      esnObj?.techRefId !== undefined &&
      esnObj?.techRefId &&
      opportunityId !== undefined &&
      opportunityId !== null
    ) {
      const data = {};
      data.comments = ocCommentsContent;
      data.esn = esnObj?.esn;
      data.techRefId = esnObj?.techRefId;
      data.retryFlag = ocShowRetryModal;
      data.opportunityId = opportunityId;
      setOcShowRetryModal(false);
      setOcShowSkipModal(false);
      setOcCommentsContent("");
      setLoadingOc(true);
      postCommentOc(data).then((response) => {
        if (response.status === 200) {
          notifyOc("Comment successfully posted");
        } else {
          notifyOc("Error occurred posting comment", "error");
        }
        setLoadingOc(false);
      });
    } else notifyOc("Esn, techrefId or opportunity id cannot be empty");
  };

  const getQuoteLineStatus = (simulationSuccessIndicator) => {
    let quoteLinestatus = "";
    if (simulationSuccessIndicator !== null && simulationSuccessIndicator?.length > 0) {
      quoteLinestatus =
        simulationSuccessIndicator === "S" ? "Buyout Complete" : simulationSuccessIndicator === "E" ? "Buyout Failed" : "Buyout Start";
    } else {
      quoteLinestatus = "Buyout Start";
    }
    return quoteLinestatus;
  };

  return (
    <>
      <Loader active={loadingOc} />
      <div css={{ width: "400px", marginRight: "100px", border: "1px solid black", borderRadius: "5px" }}>
        {Object.entries(info.leftSectionInfo).map((item, index) => {
          return (
            <div key={index} css={{ borderBottom: "1px solid gray", padding: "20px" }}>
              {item[0]}
              {" : "}{" "}
              <Body size="large" bold>
                {item[1]}
              </Body>
            </div>
          );
        })}
        <PrimeAccordionContainer>
          <PrimeAccordion multiple>
            <PrimeAccordionTab header="Account Info">
              <AccordionEntry label="Created At" text={parseDate(info?.accountInfo?.createdAt)} />
              <AccordionEntry label="Modified At" text={parseDate(info?.accountInfo?.modifiedAt)} />
            </PrimeAccordionTab>
            <PrimeAccordionTab header="Contract Account Info">
              <AccordionEntry label="SF Contract Account Id" text={info?.contractAccountInfo?.sfContractAccountId} />
              <AccordionEntry label="Shipping UUID" text={info?.contractAccountInfo?.shippingUUID} />
              <AccordionEntry label="Billing UUID" text={info?.contractAccountInfo?.billingUUID} />
              <AccordionEntry label="ERP Contract Account Number" text={info?.contractAccountInfo?.erpContractAccountNumber} />
              <AccordionEntry label="Created At" text={parseDate(info?.contractAccountInfo?.createdAt)} />
              <AccordionEntry label="Modified At" text={parseDate(info?.contractAccountInfo?.modifiedAt)} />
            </PrimeAccordionTab>
            <PrimeAccordionTab header="Contact Info">
              <SmallPrimeAccordionContainer>
                <PrimeAccordion multiple>
                  {info?.contactInfo?.map((item, index) => {
                    return (
                      <PrimeAccordionTab key={index} header={item.sfContactId}>
                        <AccordionEntry label="SF Contact ID" text={item?.sfContactId} />
                        <AccordionEntry label="Contact Business Partner ID" text={item?.contactBusinessPartnerId} />
                        <AccordionEntry label="Contact Role" text={item?.contactRole} />
                        <AccordionEntry label="Created At" text={parseDate(item?.createdAt)} />
                        <AccordionEntry label="Modified At" text={parseDate(item?.modifiedAt)} />
                        <AccordionEntry label="Status" text={item?.status} />
                      </PrimeAccordionTab>
                    );
                  })}
                </PrimeAccordion>
              </SmallPrimeAccordionContainer>
            </PrimeAccordionTab>
            <PrimeAccordionTab header="Quote Line Items">
              <SmallPrimeAccordionContainer>
                <PrimeAccordion multiple>
                  {buyoutInfo?.quoteLines?.map((quoteLineItem, index) => {
                    return (
                      <PrimeAccordionTab key={index} header={quoteLineItem.quoteLineID}>
                        <AccordionEntry label="QuoteLine Id" text={quoteLineItem?.quoteLineID} />
                        <AccordionEntry label="Quote Id" text={quoteLineItem?.quoteID} />
                        <AccordionEntry label="Status" text={getQuoteLineStatus(quoteLineItem?.simulationSuccessIndicator)} />
                        <AccordionEntry label="Message" text={quoteLineItem?.message} />
                        <Spacer />
                      </PrimeAccordionTab>
                    );
                  })}
                </PrimeAccordion>
              </SmallPrimeAccordionContainer>
            </PrimeAccordionTab>
            <PrimeAccordionTab header="Opportunity Line Items">
              <SmallPrimeAccordionContainer>
                <PrimeAccordion multiple>
                  {info?.products?.map((item, index) => {
                    return (
                      <PrimeAccordionTab key={index} header={item.id}>
                        <AccordionEntry label="ID" text={item?.id} />
                        <AccordionEntry label="SF Opportunity ID" text={item?.opportunityId} />
                        <AccordionEntry label="Status" text={item?.newState} />
                        <AccordionEntry label="ESN Retry" />
                        <Spacer />
                        {item?.esnList?.length > 0 &&
                          item?.esnList.map((es) => {
                            return (
                              <FlexRow key={es.esn} css={{ marginTop: "10px" }}>
                                <Spacer />
                                <SmallText bold css={{ marginLeft: "10px" }}>
                                  {es.esn}
                                </SmallText>
                                <Button size="small" css={{ marginLeft: "10px" }} onClick={() => retry(es)}>
                                  Retry
                                </Button>
                                <Button size="small" css={{ marginLeft: "10px" }} onClick={() => skip(es)}>
                                  Skip
                                </Button>
                              </FlexRow>
                            );
                          })}
                      </PrimeAccordionTab>
                    );
                  })}
                </PrimeAccordion>
              </SmallPrimeAccordionContainer>
            </PrimeAccordionTab>
          </PrimeAccordion>
        </PrimeAccordionContainer>
      </div>
      <Modal opened={ocShowRetryModal || ocShowSkipModal} disableOutsideClick closeButton={null}>
        <ModalBody>
          <Body size="large" bold>
            Please provide the comments below:
          </Body>
          <TextArea
            readOnly={false}
            required={false}
            disabled={false}
            error={false}
            helperTextPlacement="bottom"
            value={ocCommentsContent}
            onChange={commentsChangeCallbackOc}
            maxLength={200}
          />
        </ModalBody>
        <ModalFooter
          buttonData={{
            primary: {
              children: "Submit",
              onClick: () => {
                submitRetrySkipCommentEsn();
              },
            },
            close: {
              children: "Cancel",
              onClick: () => {
                setOcShowRetryModal(false);
                setOcShowSkipModal(false);
              },
            },
          }}
        ></ModalFooter>
      </Modal>
    </>
  );
};

const MilestoneProcess1 = (props) => {
  const { stage1, stage2, stage3, stage4, setAddonElement } = props;
  return (
    <>
      <Para stage={stage1} setAddonElement={setAddonElement}>
        Opportunity pulled into SL
      </Para>
      <Para stage={stage2} setAddonElement={setAddonElement}>
        Business Partner Request sent to MDG
      </Para>
      <Para stage={stage3} setAddonElement={setAddonElement}>
        MDG sent Business Partner to SL
      </Para>
      <Para stage={stage4} setAddonElement={setAddonElement}>
        SL updates Business Partner to SFDC
      </Para>
    </>
  );
};
const Contacts = ({ contacts }) => {
  return (
    <>
      {contacts.map((item, index) => {
        return (
          <FlexRow key={index} padding="0 0 5px 50px">
            <Person width="15px" height="15px" />
            <span css={{ paddingLeft: "10px" }}>{item}</span>
          </FlexRow>
        );
      })}
    </>
  );
};

const MilestoneProcess2 = ({ stage1, stage2, stage3, stage4, stage5, stage6, stage7, rerender, setAddonElement }) => {
  const [contacts, setContacts] = useState();

  const handler = () => {
    // setContacts(["ash", "ashi", "ashir"]);
    rerender();
  };
  return (
    <>
      <Para stage={stage1} setAddonElement={setAddonElement}>
        Create or Update Contract Account in SFDC
      </Para>
      <Para stage={stage2} setAddonElement={setAddonElement}>
        Create AddressUUIDs for Contract Account in MDG
      </Para>
      <Para stage={stage3} setAddonElement={setAddonElement}>
        Update Contact BusinessPartnerId in SFDC
      </Para>
      <Para stage={stage4} setAddonElement={setAddonElement}>
        Associate Contact to Account in MDG
      </Para>
      <Para stage={stage5} setAddonElement={setAddonElement}>
        Update AddressUUIDs in SFDC
      </Para>
      <Para stage={stage6} setAddonElement={setAddonElement}>
        Create Contract Account in MDG
      </Para>
      <Para stage={stage7} setAddonElement={setAddonElement}>
        Update Contract Account ERPContractAccountNumber in SFDC
      </Para>
    </>
  );
};

const mapState = (data, state) => {
  switch (state) {
    case States.completed:
      data.success = true;
      break;
    case States.failed:
      data.error = true;
      break;
    case States.progress:
      data.color = Colors.blue;
      break;
  }
};

const markError = (milestoneData, item) => {
  let visited = false;
  for (const key in milestoneData) {
    if (milestoneData[key] === States.progress) {
      if (!visited) {
        visited = true;
        milestoneData[key] = {};
        milestoneData[key].state = States.failed;
        milestoneData[key].errorMessage = item.description;
      } else {
        milestoneData[key] = States.failed;
      }
    }
  }
};

const OrderCreationInSOMProcess = (props) => {
  const { stage1, stage2, stage3, stage4, stage5, stage6, setAddonElement } = props;
  return (
    <>
      <Para stage={stage1} setAddonElement={setAddonElement}>
        Get Data from SFDC
      </Para>
      <Para stage={stage2} setAddonElement={setAddonElement}>
        Complete Data validation
      </Para>
      <Para stage={stage3} setAddonElement={setAddonElement}>
        Send request to create order in SOM
      </Para>
      <Para stage={stage4} setAddonElement={setAddonElement}>
        Update ERP Provider Order Number in SFDC
      </Para>
      <Para stage={stage5} setAddonElement={setAddonElement}>
        Catalog service update billing
      </Para>
      <Para stage={stage6} setAddonElement={setAddonElement}>
        Device Level updates to SAP
      </Para>
    </>
  );
};

const OrderRewriteProcess = (props) => {
  const { stage1, stage2, stage3, stage4, setAddonElement } = props;
  return (
    <>
      <Para stage={stage1} setAddonElement={setAddonElement}>
        Order Rewrite Ready
      </Para>
      <Para stage={stage2} setAddonElement={setAddonElement}>
        Prepare Request for Rewrite Line items
      </Para>
      <Para stage={stage3} setAddonElement={setAddonElement}>
        Order Rewrite Line items status check
      </Para>
      <Para stage={stage4} setAddonElement={setAddonElement}>
        Order Rewrite Completed
      </Para>
    </>
  );
};

const OrderRACProcess = (props) => {
  const {
    stage1,
    stage2,
    stage3,
    stage4,
    stage5,
    stage6,
    stage7,
    hideStage1,
    hideStage2,
    hideStage3,
    hideStage4,
    hideStage5,
    hideStage6,
    hideStage7,
    hideStage8,
    stage8,
    setAddonElement,
  } = props;
  return (
    <>
      <Para stage={stage1} style={{ display: hideStage1 }} setAddonElement={setAddonElement}>
        Check Reveal Account Exists
      </Para>
      <Para stage={stage2} style={{ display: hideStage2 }} setAddonElement={setAddonElement}>
        Create Reveal Account
      </Para>
      <Para stage={stage3} style={{ display: hideStage3 }} setAddonElement={setAddonElement}>
        Unsuspend Reveal Account
      </Para>
      <Para stage={stage4} style={{ display: hideStage4 }} setAddonElement={setAddonElement}>
        Check if Account updates needed
      </Para>
      <Para stage={stage5} style={{ display: hideStage5 }} setAddonElement={setAddonElement}>
        Update Features
      </Para>
      <Para stage={stage6} style={{ display: hideStage6 }} setAddonElement={setAddonElement}>
        Get FSD Count from SFDC
      </Para>
      <Para stage={stage7} style={{ display: hideStage7 }} setAddonElement={setAddonElement}>
        Update FSD in SFDC
      </Para>
      <Para stage={stage8} style={{ display: hideStage8 }} setAddonElement={setAddonElement}>
        Update FSD in Reveal
      </Para>
    </>
  );
};

const CancellationProcess = (props) => {
  const { stage1, stage2, stage3, stage4, stage5, stage6, stage7, setAddonElement, subType } = props;

  if (subType === "Full Cancellation") {
    return (
      <>
        {stage1 ? (
          <Para stage={stage1} setAddonElement={setAddonElement}>
            Buyout Simulation
          </Para>
        ) : (
          <></>
        )}
        <Para stage={stage2} setAddonElement={setAddonElement}>
          All Lines processed
        </Para>
        <Para stage={stage3} setAddonElement={setAddonElement}>
          Financial status updated In SFDC
        </Para>
        <Para stage={stage4} setAddonElement={setAddonElement}>
          Decommision account completed
        </Para>
        <Para stage={stage5} setAddonElement={setAddonElement}>
          Kill SIM / ESN Clean-Up Cases processed
        </Para>
        <Para stage={stage6} setAddonElement={setAddonElement}>
          Opportunity status updated in SFDC
        </Para>
        <Para stage={stage7} setAddonElement={setAddonElement}>
          Cancellation completed
        </Para>
      </>
    );
  } else {
    // Partial Cancellation
    return (
      <>
        {stage1 ? (
          <Para stage={stage1} setAddonElement={setAddonElement}>
            Buyout Simulation
          </Para>
        ) : (
          <></>
        )}
        <Para stage={stage2} setAddonElement={setAddonElement}>
          All Lines Processed
        </Para>
        <Para stage={stage3} setAddonElement={setAddonElement}>
          Opportunity status updated in SFDC
        </Para>
        <Para stage={stage4} setAddonElement={setAddonElement}>
          Cancellation Completed
        </Para>
      </>
    );
  }
};

export default function Milestone({ opportunityId, sfOppId, subType = "Full Cancellation" }) {
  const { handleBuyoutStatus, handleCancellation } = useMilestone({ subType });

  const [info, setInfo] = useState(makeInfo(null));
  const [buyoutInfo, setBuyoutInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState();
  const [failed, setFailed] = useState(false);
  const [showRetryModal, setShowRetryModal] = useState(false);
  const [showSkipModal, setShowSkipModal] = useState(false);
  const [commentsContent, setCommentsContent] = useState("");
  const [salesforceOpportunityId, setSalesforceOpportunityId] = useState("");
  const [milestoneData, setMilestoneData] = useState(milestones);

  const markMilestone1Complete = useCallback((accordionData, milestone1Data) => {
    milestone1Data.stage1 = States.completed;
    milestone1Data.stage2 = States.completed;
    milestone1Data.stage3 = States.completed;
    milestone1Data.stage4 = States.completed;
    accordionData[0].state = States.completed;
    accordionData[1].state = States.completed;
    accordionData[2].state = States.completed;
    accordionData[3].state = States.completed;
    accordionData[4].state = States.completed;
    accordionData[5].state = States.progress;
  }, []);
  const markMilestone2Complete = useCallback((accordionData, milestone2Data) => {
    milestone2Data.stage1 = States.completed;
    milestone2Data.stage2 = States.completed;
    milestone2Data.stage3 = States.completed;
    milestone2Data.stage4 = States.completed;
    milestone2Data.stage5 = States.completed;
    milestone2Data.stage6 = States.completed;
    milestone2Data.stage7 = States.completed;
    accordionData[3].state = States.completed;
    accordionData[4].state = States.completed;
    accordionData[5].state = States.completed;
  }, []);

  const handleOrderCreationInSOM = useCallback((item, orderCreationInSOMData, accordionData) => {
    if (item.state == "OCDataReady") {
      orderCreationInSOMData.stage1 = States.completed;
      orderCreationInSOMData.stage2 = States.progress;
      accordionData[6].state = States.progress;
    } else if (item.state == "OCValidationSuccess") {
      orderCreationInSOMData.stage2 = States.completed;
      orderCreationInSOMData.stage3 = States.progress;
      accordionData[6].state = States.progress;
    } else if (item.state == "OCValidationFailed") {
      markError(orderCreationInSOMData, item);
      accordionData[6].state = States.failed;
    } else if (item.state == "OCSentRequestToSOM") {
      orderCreationInSOMData.stage3 = States.completed;
      orderCreationInSOMData.stage4 = States.progress;
      accordionData[6].state = States.progress;
    } else if (item.state == "OCSentRequestToSOMError") {
      markError(orderCreationInSOMData, item);
      accordionData[6].state = States.failed;
    } else if (item.state == "OCUpdatedOrderIdInSFDC") {
      orderCreationInSOMData.stage4 = States.completed;
      orderCreationInSOMData.stage5 = States.progress;
      accordionData[6].state = States.progress;
    } else if (item.state == "OCCatalogServiceUpdatesCompleted") {
      orderCreationInSOMData.stage5 = States.completed;
    } else if (item.state == "OCDeviceLevelUpdatesCompleted") {
      orderCreationInSOMData.stage6 = States.completed;
    }
    if (item.state == "OCCompleted") {
      orderCreationInSOMData.stage1 = States.completed;
      orderCreationInSOMData.stage2 = States.completed;
      orderCreationInSOMData.stage3 = States.completed;
      orderCreationInSOMData.stage4 = States.completed;
      orderCreationInSOMData.stage5 = States.completed;
      orderCreationInSOMData.stage6 = States.completed;
      accordionData[6].state = States.completed;
    }
  }, []);

  const resetRACStageVisibility = (orderRACData) => {
    orderRACData.hideStage1 = "";
    orderRACData.hideStage2 = "";
    orderRACData.hideStage3 = "";
    orderRACData.hideStage4 = "";
    orderRACData.hideStage5 = "";
    orderRACData.hideStage6 = "";
    orderRACData.hideStage7 = "";
    orderRACData.hideStage8 = "";
  };

  const handleRACreation = useCallback((item, orderRACData, accordionData, states) => {
    const statesList = states.map((item) => {
      return item.state;
    });
    switch (item.state) {
      case "RAExistsTrue":
        resetRACStageVisibility(orderRACData);
        accordionData[6].state = States.completed;
        orderRACData.stage1 = States.completed;
        orderRACData.hideStage2 = "none";
        orderRACData.stage3 = States.progress;
        orderRACData.stage4 = "";
        orderRACData.stage5 = "";
        orderRACData.stage6 = "";
        orderRACData.stage7 = "";
        orderRACData.stage8 = "";
        accordionData[7].state = States.progress;
        break;
      case "RAExistFalse":
        resetRACStageVisibility(orderRACData);
        accordionData[6].state = States.completed;
        orderRACData.stage1 = States.completed;
        orderRACData.stage2 = States.progress;
        orderRACData.hideStage3 = "none";
        orderRACData.hideStage4 = "none";
        orderRACData.hideStage5 = "none";
        orderRACData.stage6 = "";
        orderRACData.stage7 = "";
        orderRACData.stage8 = "";
        accordionData[7].state = States.progress;
        break;
      case "RAExistsError":
        resetRACStageVisibility(orderRACData);
        accordionData[6].state = States.completed;
        orderRACData.stage1 = States.failed;
        orderRACData.stage2 = "";
        orderRACData.stage3 = "";
        orderRACData.stage4 = "";
        orderRACData.stage5 = "";
        orderRACData.stage6 = "";
        orderRACData.stage7 = "";
        orderRACData.stage8 = "";
        markError(orderRACData, item);
        accordionData[7].state = States.failed;
        break;
      case "RACreateSucc":
        resetRACStageVisibility(orderRACData);
        accordionData[6].state = States.completed;
        orderRACData.stage1 = States.completed;
        orderRACData.stage2 = States.completed;
        orderRACData.hideStage3 = "none";
        orderRACData.hideStage4 = "none";
        orderRACData.hideStage5 = "none";
        orderRACData.stage6 = States.progress;
        orderRACData.stage7 = "";
        orderRACData.stage8 = "";
        accordionData[7].state = States.progress;
        break;
      case "RACreateFail":
        resetRACStageVisibility(orderRACData);
        accordionData[6].state = States.completed;
        orderRACData.stage1 = States.completed;
        orderRACData.stage2 = States.failed;
        orderRACData.hideStage3 = "none";
        orderRACData.hideStage4 = "none";
        orderRACData.hideStage5 = "none";
        orderRACData.stage6 = "";
        orderRACData.stage7 = "";
        orderRACData.stage8 = "";
        markError(orderRACData, item);
        accordionData[7].state = States.failed;
        break;
      case "RAUnsuspendFailed":
        resetRACStageVisibility(orderRACData);
        accordionData[6].state = States.completed;
        orderRACData.stage1 = States.completed;
        orderRACData.hideStage2 = "none";
        orderRACData.stage3 = States.failed;
        orderRACData.stage4 = "";
        orderRACData.stage5 = "";
        orderRACData.stage6 = "";
        orderRACData.stage7 = "";
        orderRACData.stage8 = "";
        markError(orderRACData, item);
        accordionData[7].state = States.failed;
        break;
      case "RAShouldUpdateTrue":
        resetRACStageVisibility(orderRACData);
        accordionData[6].state = States.completed;
        orderRACData.stage1 = States.completed;
        orderRACData.hideStage2 = "none";
        orderRACData.hideStage3 = "none";
        orderRACData.stage4 = States.completed;
        orderRACData.stage5 = States.progress;
        orderRACData.stage6 = "";
        orderRACData.stage7 = "";
        orderRACData.stage8 = "";
        accordionData[7].state = States.progress;
        break;
      case "RAUpdateFeatureSucc":
        resetRACStageVisibility(orderRACData);
        accordionData[6].state = States.completed;
        orderRACData.stage1 = States.completed;
        orderRACData.hideStage2 = "none";
        orderRACData.hideStage3 = "none";
        orderRACData.stage4 = States.completed;
        orderRACData.stage5 = States.completed;
        orderRACData.stage6 = States.progress;
        orderRACData.stage7 = "";
        orderRACData.stage8 = "";
        accordionData[7].state = States.progress;
        break;
      case "RAUpdateFeatureFail":
        resetRACStageVisibility(orderRACData);
        accordionData[6].state = States.completed;
        orderRACData.stage1 = States.completed;
        orderRACData.hideStage2 = "none";
        orderRACData.hideStage3 = "none";
        orderRACData.stage4 = States.completed;
        orderRACData.stage5 = States.failed;
        orderRACData.stage6 = "";
        orderRACData.stage7 = "";
        orderRACData.stage8 = "";
        markError(orderRACData, item);
        accordionData[7].state = States.failed;
        break;
      case "RAGetFSDCountFromSFDCSucc":
        resetRACStageVisibility(orderRACData);
        accordionData[6].state = States.completed;
        orderRACData.stage1 = States.completed;
        orderRACData.hideStage2 = "none";
        orderRACData.hideStage3 = "none";
        orderRACData.stage4 = States.completed;
        orderRACData.stage5 = States.completed;
        orderRACData.stage6 = States.completed;
        orderRACData.stage7 = States.progress;
        orderRACData.stage8 = "";
        accordionData[7].state = States.progress;
        break;
      case "RAGetFSDCountFromSFDCFail":
        resetRACStageVisibility(orderRACData);
        accordionData[6].state = States.completed;
        orderRACData.stage1 = States.completed;
        orderRACData.hideStage2 = "none";
        orderRACData.hideStage3 = "none";
        orderRACData.stage4 = States.completed;
        orderRACData.stage5 = States.completed;
        orderRACData.stage6 = States.failed;
        orderRACData.stage7 = "";
        orderRACData.stage8 = "";
        markError(orderRACData, item);
        accordionData[7].state = States.failed;
        break;
      case "RAUpdateFSDCountInSFDCSucc":
        resetRACStageVisibility(orderRACData);
        accordionData[6].state = States.completed;
        orderRACData.stage1 = States.completed;
        orderRACData.hideStage2 = "none";
        orderRACData.hideStage3 = "none";
        orderRACData.stage4 = States.completed;
        orderRACData.stage5 = States.completed;
        orderRACData.stage6 = States.completed;
        orderRACData.stage7 = States.completed;
        orderRACData.stage8 = States.progress;
        accordionData[7].state = States.progress;
        break;
      case "RAUpdateFSDCountInSFDCFail":
        resetRACStageVisibility(orderRACData);
        accordionData[6].state = States.completed;
        orderRACData.stage1 = States.completed;
        orderRACData.hideStage2 = "none";
        orderRACData.hideStage3 = "none";
        orderRACData.stage4 = States.completed;
        orderRACData.stage5 = States.completed;
        orderRACData.stage6 = States.completed;
        orderRACData.stage7 = States.failed;
        orderRACData.stage8 = "";
        markError(orderRACData, item);
        accordionData[7].state = States.failed;
        break;
      case "RACompleted":
        resetRACStageVisibility(orderRACData);
        accordionData[6].state = States.completed;
        if (statesList.includes("RAExistFalse")) {
          orderRACData.stage1 = States.completed;
          orderRACData.stage2 = States.completed;
          orderRACData.hideStage3 = "none";
          orderRACData.hideStage4 = "none";
          orderRACData.hideStage5 = "none";
          orderRACData.stage6 = States.completed;
          statesList.includes("RAUpdateFSDCountInSFDCSucc") ? (orderRACData.stage7 = States.completed) : (orderRACData.hideStage7 = "none");
          orderRACData.stage8 = States.completed;
        }
        if (statesList.includes("RAExistsTrue")) {
          resetRACStageVisibility(orderRACData);
          orderRACData.stage1 = States.completed;
          orderRACData.hideStage2 = "none";
          if (statesList.includes("RAShouldUpdateTrue")) {
            orderRACData.hideStage3 = "none";
            orderRACData.stage4 = States.completed;
            orderRACData.stage5 = States.completed;
            orderRACData.stage6 = States.completed;
            statesList.includes("RAUpdateFSDCountInSFDCSucc") ? (orderRACData.stage7 = States.completed) : (orderRACData.hideStage7 = "none");
            orderRACData.stage8 = States.completed;
          } else {
            orderRACData.stage3 = States.completed;
            orderRACData.hideStage4 = "none";
            orderRACData.hideStage5 = "none";
            orderRACData.hideStage6 = "none";
            orderRACData.hideStage7 = "none";
            orderRACData.hideStage8 = "none";
          }
        }
        accordionData[7].state = States.completed;
        break;
      case "RAUpdateFSDFail":
        resetRACStageVisibility(orderRACData);
        accordionData[6].state = States.completed;
        if (statesList.includes("RAExistFalse")) {
          orderRACData.stage1 = States.completed;
          orderRACData.stage2 = States.completed;
          orderRACData.hideStage3 = "none";
          orderRACData.hideStage4 = "none";
          orderRACData.hideStage5 = "none";
          orderRACData.stage6 = States.completed;
          statesList.includes("RAUpdateFSDCountInSFDCSucc") ? (orderRACData.stage7 = States.completed) : (orderRACData.hideStage7 = "none");
          orderRACData.stage8 = States.failed;
        }
        if (statesList.includes("RAExistsTrue")) {
          orderRACData.stage1 = States.completed;
          orderRACData.hideStage2 = "none";
          orderRACData.hideStage3 = "none";
          orderRACData.stage4 = States.completed;
          orderRACData.stage5 = States.completed;
          orderRACData.stage6 = States.completed;
          statesList.includes("RAUpdateFSDCountInSFDCSucc") ? (orderRACData.stage7 = States.completed) : (orderRACData.hideStage7 = "none");
          orderRACData.stage8 = States.failed;
        }
        markError(orderRACData, item);
        accordionData[7].state = States.failed;
        break;
      case "RACreateRetry":
        accordionData[6].state = States.completed;
        break;
    }
  }, []);
  const handleOrderRewrite = useCallback((item, orderRewriteData, accordionData) => {
    switch (item.state) {
      case "OrderRewriteReady":
        orderRewriteData.stage1 = States.completed;
        orderRewriteData.stage2 = States.progress;
        accordionData[8].state = States.progress;
        break;
      case "OrderRewriteBuildLineItemsSucc":
        orderRewriteData.stage2 = States.completed;
        orderRewriteData.stage3 = States.progress;
        accordionData[8].state = States.progress;
        break;
      case "OrderRewriteCheckStatusFailed":
        markError(orderRewriteData, item);
        accordionData[8].state = States.failed;
        break;
      case "OrderRewriteCheckStatusSuccess":
        orderRewriteData.stage3 = States.completed;
        orderRewriteData.stage4 = States.progress;
        accordionData[8].state = States.progress;
        break;
      case "OrderRewriteCompleted":
        orderRewriteData.stage4 = States.completed;
        accordionData[8].state = States.completed;
        break;
      case "OrderRewriteApexStored":
        break;
      case "OrderRewriteFailed":
        markError(orderRewriteData, item);
        accordionData[8].state = States.failed;
        break;
      case "OrderRewriteFailureRetry":
        break;
    }
  }, []);

  const preprocessData = useCallback((data, buyOutData) => {
    const states = data.states;
    const updatedData = cloneDeep(milestones);
    const orderCreationInSOMData = {};
    const orderRewriteData = {};
    const orderRACData = {};
    const cancellationData = {};
    const milestone1Data = {},
      milestone2Data = {};
    let m2Completed = false;
    states.sort((a, b) => {
      let c = new Date(a.creationTime);
      let d = new Date(b.creationTime);
      return c - d;
    });

    if (buyOutData && Object.keys(buyOutData).length) {
      handleBuyoutStatus(cancellationData, updatedData, buyOutData);
    }
    states.forEach((item) => {
      handleOrderCreationInSOM(item, orderCreationInSOMData, updatedData);
      handleOrderRewrite(item, orderRewriteData, updatedData);
      handleRACreation(item, orderRACData, updatedData, states); // eslint-disable-line
      handleCancellation(item, cancellationData, updatedData); // eslint-disable-line
      if (m2Completed) {
        return;
      }
      switch (item.state) {
        case "New":
          milestone1Data.stage1 = States.completed;
          milestone1Data.stage2 = States.progress;
          updatedData[0].state = States.completed;
          updatedData[1].state = States.completed;
          updatedData[2].state = States.progress;
          break;
        case "M1WaitingBP":
          milestone1Data.stage2 = States.completed;
          milestone1Data.stage3 = States.progress;
          break;
        case "M1GotBP":
          milestone1Data.stage3 = States.completed;
          milestone1Data.stage4 = States.progress;
          break;
        case "M1Failed": {
          let error = {};
          error.state = States.failed;
          error.errorMessage = item.description;
          milestone1Data.stage3 = error;
          updatedData[2].state = States.failed;
          break;
        }
        case "M1UpdateBPToSFDC":
          milestone1Data.stage4 = States.completed;
          break;
        case "M1BpPresent":
        case "M1Completed":
          milestone1Data.stage4 = States.completed;
          updatedData[2].state = States.completed;
          break;
        case "M2FailedCreateCAInSFDC":
          milestone2Data.stage1 = States.failed;
          updatedData[5].state = States.failed;
          break;
        case "M2CreatedCAInSFDC":
          markMilestone1Complete(updatedData, milestone1Data);
          updatedData[3].state = States.completed;
          updatedData[4].state = States.completed;
          updatedData[5].state = States.progress;
          milestone2Data.stage1 = States.completed;
          milestone2Data.stage2 = States.progress;
          break;
        case "M2WaitingAddressIds":
          markMilestone1Complete(updatedData, milestone1Data);
          updatedData[3].state = States.completed;
          updatedData[4].state = States.completed;
          updatedData[5].state = States.progress;
          milestone2Data.stage1 = States.completed;
          milestone2Data.stage2 = States.progress;
          break;
        case "M2WaitingContactBP":
          markMilestone1Complete(updatedData, milestone1Data);
          milestone2Data.stage1 = States.completed;
          milestone2Data.stage2 = States.progress;
          break;
        case "M2GotAddressIds":
          markMilestone1Complete(updatedData, milestone1Data);
          milestone2Data.stage2 = States.completed;
          milestone2Data.stage3 = States.progress;
          break;
        case "M2GotBP":
          markMilestone1Complete(updatedData, milestone1Data);
          milestone2Data.stage2 = States.completed;
          milestone2Data.stage3 = States.progress;
          break;
        case "M2ContactBPUpdatedInSFDC":
          markMilestone1Complete(updatedData, milestone1Data);
          milestone2Data.stage3 = States.completed;
          milestone2Data.stage4 = States.progress;
          milestone2Data.stage5 = States.progress;
          break;
        case "UpdatedCAinSFDC":
        case "M2CAAddressIdUpdated":
          markMilestone1Complete(updatedData, milestone1Data);
          milestone2Data.stage5 = States.completed;
          break;
        case "M2WaitingAssociation":
        case "M2ContactWaitingAssociation":
          markMilestone1Complete(updatedData, milestone1Data);
          milestone2Data.stage4 = States.progress;
          break;
        case "M2ContactAssociated":
          markMilestone1Complete(updatedData, milestone1Data);
          milestone2Data.stage4 = States.completed;
          milestone2Data.stage5 = States.progress;
          break;
        case "M2WaitingCACreateInMDG":
          markMilestone1Complete(updatedData, milestone1Data);
          milestone2Data.stage6 = States.progress;
          break;
        case "M2GotCA":
          markMilestone1Complete(updatedData, milestone1Data);
          milestone2Data.stage6 = States.completed;
          milestone2Data.stage7 = States.progress;
          break;
        case "M2UpdatedCAInSFDC":
          markMilestone1Complete(updatedData, milestone1Data);
          milestone2Data.stage7 = States.completed;
          break;
        case "M2Completed":
          markMilestone1Complete(updatedData, milestone1Data);
          markMilestone2Complete(updatedData, milestone2Data);
          m2Completed = true;
          break;
        case "OCCompleted":
          updatedData[6].state = States.completed;
          break;
        case "M2Failed": {
          updatedData[5].state = States.failed;
          markError(milestone2Data, item);
          break;
        }
      }
    });

    updatedData.forEach((item) => {
      mapState(item, item.state);
      delete item.state;
    });
    if (Object.keys(milestone1Data).length > 0) {
      updatedData[2].data = <MilestoneProcess1 {...milestone1Data}></MilestoneProcess1>;
      updatedData[2].expandable = true;
    }
    if (Object.keys(milestone2Data).length > 0) {
      updatedData[5].data = <MilestoneProcess2 {...milestone2Data}></MilestoneProcess2>;
      updatedData[5].expandable = true;
    }
    if (Object.keys(orderCreationInSOMData).length > 0) {
      updatedData[6].data = <OrderCreationInSOMProcess {...orderCreationInSOMData}></OrderCreationInSOMProcess>;
      updatedData[6].expandable = true;
    }
    /* eslint-disable */
    if (Object.keys(orderRACData).length > 0) {
      updatedData[7].data = <OrderRACProcess {...orderRACData}></OrderRACProcess>;
      updatedData[7].expandable = true;
    }
    /* eslint-enable */
    if (Object.keys(orderRewriteData).length > 0) {
      updatedData[8].data = <OrderRewriteProcess {...orderRewriteData}></OrderRewriteProcess>;
      updatedData[8].expandable = true;
    }
    /* eslint-disable */
    if (Object.keys(cancellationData).length > 0) {
      updatedData[9].data = <CancellationProcess subType={subType} {...cancellationData}></CancellationProcess>;
      updatedData[9].expandable = true;
    }
    /* eslint-enable */
    setMilestoneData(updatedData);
  }, []);

  const notify = (content, type) => {
    setNotification(<Notification key={generate()} type={type} title={content} fullBleed={true} />);
  };
  const commentsChangeCallback = (e) => {
    setCommentsContent(e.target.value);
  };
  const retry = () => {
    setCommentsContent("");
    setShowRetryModal(true);
  };
  const skip = () => {
    setCommentsContent("");
    setShowSkipModal(true);
  };
  const submitRetrySkipComment = () => {
    const data = {};
    data.comments = commentsContent;
    data.opportunityId = opportunityId;
    data.retryFlag = showRetryModal;
    setShowRetryModal(false);
    setShowSkipModal(false);
    setCommentsContent("");
    setLoading(true);
    postComment(data).then((response) => {
      if (response.status === 200) {
        notify("Comment successfully posted");
      } else {
        notify("Error occurred posting comment", "error");
      }
      setLoading(false);
    });
  };

  const fetchData = async () => {
    setLoading(true);
    const milestoneData = await getMilestoneData(opportunityId);
    if (milestoneData.status === 200) {
      const data = milestoneData?.data?.data;
      setInfo(makeInfo(data));
      setFailed(data?.retry ?? false);
      setSalesforceOpportunityId(data?.salesForceOpportunityId ?? "");
      let buyOutData = {};
      if (data && data.states && data.states.some((e) => e.state === "BuyoutReady")) {
        const buyOutStatus = await getBuyoutStatus(sfOppId);
        if (buyOutStatus.status === 200) {
          buyOutData = buyOutStatus?.data?.data;
          setBuyoutInfo(buyOutData);
        }
      }
      preprocessData(data, buyOutData);
    } else {
      notify("Error fetching milestone data", "error");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <FlexRow justifyContent="space-between" padding="10px 0">
        <Title primitive="p" size="medium">
          Milestone Details - Opportunity Id : {salesforceOpportunityId}
        </Title>
        <FlexRow
          css={{ justifyContent: "center", alignItems: "center", backgroundColor: "gray", width: "35px", height: "35px", borderRadius: "5px" }}
          onClick={fetchData}
        >
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M12.7875 2.20312C11.4281 0.84375 9.5625 0 7.49062 0C3.34687 0 0 3.35625 0 7.5C0 11.6438 3.34687 15 7.49062 15C10.9875 15 13.9031 12.6094 14.7375 9.375H12.7875C12.0187 11.5594 9.9375 13.125 7.49062 13.125C4.3875 13.125 1.86562 10.6031 1.86562 7.5C1.86562 4.39688 4.3875 1.875 7.49062 1.875C9.04687 1.875 10.4344 2.52188 11.4469 3.54375L8.42812 6.5625H14.9906V0L12.7875 2.20312Z"
              fill="white"
            />
          </svg>
        </FlexRow>
      </FlexRow>
      <Line type="light"></Line>
      {notification}
      <Loader active={loading} />
      <FlexRow alignItems="flex-start" justifyContent="space-between" paddingTop="30px">
        <FlexRow alignItems="flex-start">
          <Info info={info} opportunityId={opportunityId} buyoutInfo={buyoutInfo}></Info>
          <Accordion>
            {milestoneData.map((item, index) => {
              return (
                <AccordionItem key={index} {...item} index={index + 1}>
                  {item.data}
                </AccordionItem>
              );
            })}
          </Accordion>
        </FlexRow>
        {hasPrivilege("ENABLE_PAGES_ORDER_MILESTONES_RETRY") && failed && (
          <FlexRow>
            <Button size="small" css={{ marginRight: "10px" }} onClick={retry}>
              Retry
            </Button>
            <Button size="small" onClick={skip}>
              Skip
            </Button>
          </FlexRow>
        )}
      </FlexRow>
      <Shipment salesforceOpportunityId={salesforceOpportunityId}></Shipment>
      <Modal opened={showRetryModal || showSkipModal} disableOutsideClick closeButton={null}>
        <ModalBody>
          <Body size="large" bold>
            Please provide the comments below:
          </Body>
          <TextArea
            readOnly={false}
            required={false}
            disabled={false}
            error={false}
            helperTextPlacement="bottom"
            value={commentsContent}
            onChange={commentsChangeCallback}
            maxLength={200}
          />
        </ModalBody>
        <ModalFooter
          buttonData={{
            primary: {
              children: "Submit",
              onClick: () => {
                submitRetrySkipComment();
              },
            },
            close: {
              children: "Cancel",
              onClick: () => {
                setShowRetryModal(false);
                setShowSkipModal(false);
              },
            },
          }}
        ></ModalFooter>
      </Modal>
    </>
  );
}

export const MilestoneMetadata = {
  name: "Milestone Details",
  id: "milestone_details",
  component: lazy(() => import("./milestone")),
  route: "/milestone_details",
};
